import axios from 'axios';
import store from '../../store';

const domainAPI = store.state.domainAPI;

// Get Customer Dashboard Status
const adm_dashboardService_GetDashboardData = (data) =>
  axios.get(domainAPI + '/api/admin/dashboard', {
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

export { adm_dashboardService_GetDashboardData };
