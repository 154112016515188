<template>
  <div>
    <dashboard-navbar></dashboard-navbar>
    <div class="main-content">
      <h5 class="blue-text font-weight-bold">注文状況</h5>
      <div class="row reports">
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
          <div class="report-item">
            <div class="d-block">
              <h1 class="float-left icon">
                <img
                  src="../../../public/img/icons/ico-request-accepted.svg"
                  alt="Request Accepted"
                />
              </h1>
              <h1 class="float-right count">
                {{ reports ? reports.no_of_pending : 0 }}
              </h1>
            </div>
            <div class="d-block">
              <h5 class="title">依頼中</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
          <div class="report-item">
            <div class="d-block">
              <h1 class="float-left icon">
                <img
                  src="../../../public/img/icons/ico-pending-verification.svg"
                  alt="Pending Verification"
                />
              </h1>
              <h1 class="float-right count">
                {{ reports ? reports.no_of_delivered : 0 }}
              </h1>
            </div>
            <div class="d-block">
              <h5 class="title">納品確認</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
          <div class="report-item">
            <div class="d-block">
              <h1 class="float-left icon">
                <img
                  src="../../../public/img/icons/ico-modification-request.svg"
                  alt="Modification Request"
                />
              </h1>
              <h1 class="float-right count">
                {{ reports ? reports.no_of_correction : 0 }}
              </h1>
            </div>
            <div class="d-block">
              <h5 class="title">修正依頼</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
          <div class="report-item">
            <div class="d-block">
              <h1 class="float-left icon">
                <img
                  src="../../../public/img/icons/ico-confirmed.svg"
                  alt="confirmed"
                />
              </h1>
              <h1 class="float-right count">
                {{ reports ? reports.no_of_confirmed : 0 }}
              </h1>
            </div>
            <div class="d-block">
              <h5 class="title">納品確認済</h5>
            </div>
          </div>
        </div>
      </div>
      <h5 class="mt-4 blue-text font-weight-bold">ユーザーステータス</h5>
      <div class="row reports">
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
          <div class="report-item">
            <div class="d-block">
              <h1 class="float-left icon">
                <img
                  src="../../../public/img/icons/ico-users.svg"
                  alt="No of Users"
                />
              </h1>
              <h1 class="float-right count">
                {{ reports ? reports.noOfRegisteredCustomers : 0 }}
              </h1>
            </div>
            <div class="d-block">
              <h5 class="title">登録済みユーザー数</h5>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4"></div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4"></div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4"></div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Services
import { adm_dashboardService_GetDashboardData } from '../../services/admin/dashboard';

// Import Custom Components
import dashboardNavbar from '../../components/admin/Dashboard-Navbar.vue';
import mainFooter from '../../components/customer/Main-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },

  components: {
    'dashboard-navbar': dashboardNavbar,
    'main-footer': mainFooter,
  },

  data() {
    return {
      reports: null,
    };
  },

  mounted() {
    this.getDashboardData();
  },

  methods: {
    getDashboardData() {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      adm_dashboardService_GetDashboardData({ token: accessToken })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.reports = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* Init Style */
.main-content {
  padding: 110px 60px 150px 350px;
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 120px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 120px 10px 80px 10px;
  }
}

/* Reports */
@media only screen and (min-width: 1450px) {
  .reports .col-xl-2 {
    flex: 40 0 16.666667%;
    max-width: 30.666667%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1449px) {
  .reports .col-xl-2 {
    flex: 0 0 24.333333%;
    max-width: 30.33333333333%;
  }
}

.reports .col-12 .report-item {
  height: 150px;
  width: 100%;
  padding: 0px 40px 0px 40px;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1440px) {
  .reports .col-12 .report-item {
    padding: 10px 20px 10px 20px;
  }
}

.reports div:nth-child(1) .report-item {
  background: #1f7ce5;
}

.reports div:nth-child(2) .report-item {
  background: #0a64c7;
}

.reports div:nth-child(3) .report-item {
  background: #034ea1;
}

.reports div:nth-child(4) .report-item {
  background: #003a7b;
}

.reports .col-12 .report-item .d-block:nth-child(1) {
  height: 100px;
  width: 100%;
}

.reports .col-12 .report-item .d-block:nth-child(2) {
  height: 50px;
  width: 100%;
}

.reports .col-12 .report-item .icon {
  margin-top: 25px;
}

.reports .col-12 .report-item .count {
  margin-top: 14px;
  font-size: 60px;
}

@media only screen and (max-width: 1599px) {
  .reports .col-12 .report-item .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1440px) {
  .reports .col-12 .report-item .icon {
    font-size: 40px;
  }

  .reports .col-12 .report-item .count {
    margin-top: 10px;
    font-size: 60px;
  }
}
</style>
